const lang = {
    title_button_go_to_back: "Atrás",
    support_title_main: "Crear un ticket",
    support_emergency_button: "Emergencia",
    support_help_button: "Centro de ayuda",
    notifications_title: "Notificaciones",
    notifications_show_all: "Todas",
    notifications_show_alerts: "Alertas",
    notifications_back_button: "Atrás",
    notifications_notification_set_read: "Marcar como leída",
    notifications_notification_set_unread: "Marcar como no leída",
    notifications_notification_delete: "Eliminar notificación",
    notifications_empty_state_title: "No hay notificaciones disponibles en este momento",
    notifications_empty_state_text: "No tienes ninguna notificación, revisa en otra ocasión",
    support_main_title_drawer: "Centro de ayuda",
    support_main_description_drawer: "¡Deja aquí tus datos! Te contactaremos en breve con una solución.",
    support_title_section_personal_data: "Datos personales",
    support_input_label_id_document: "Documento de identidad",
    support_input_label_user_name: "Nombres",
    support_input_placeholder_user_name_example: "Ej. Luis Enrique",
    support_input_label_user_lastname: "Apellidos",
    support_input_placeholder_user_lastname_example: "Ej. Cortes López",
    support_input_label_user_email: "Correo electrónico",
    support_input_placeholder_user_email_example: "Ej. lcortes@neu.com.co",
    support_input_label_user_phone: "Teléfono",
    support_input_placeholder_user_phone_example: "Ej. 3013865253",
    support_input_label_user_address: "Dirección",
    support_input_placeholder_user_address_example: "Ej. Calle 27 S # 100 - 14",
    support_select_label_service: "Servicio",
    support_select_label_type_ticket: "Tipo de ticket",
    support_select_option_customer_requests: "Peticiones",
    support_select_option_customer_claims: "Reclamos",
    support_select_option_customer_complaints: "Quejas",
    support_select_option_customer_suggestions: "Sugerencias",
    support_select_label_area_ticket: "Área del ticket",
    support_select_option_billing: "Facturación",
    support_select_option_operations: "Operaciones",
    support_select_option_tech: "Tecnología",
    support_select_option_accounting: "Contabilidad",
    support_select_option_legal: "Legal",
    support_select_option_customer_success: "Customer Success",
    support_title_section_detail_ticket: "Detalles del ticket",
    support_input_label_request_ticket: "Describe tu solicitud",
    support_title_section_upload_file: "Agregar Documento (opcional)",
    support_description_section_upload_file: "Puedes agregar tu factura o algún documento, que creas necesario, para completar tu solicitud.",
    support_button_label_upload_file: "Agregar archivo",
    support_button_label_send_form: "Enviar",
    user_picker_placeholder_account: "Nombre de la cuenta",
    user_title_section_personal_data: "Datos personales",
    user_title_section_your_info: "Tu información",
    user_input_label_id_document: "Documento de identidad",
    user_input_label_names: "Nombres",
    user_input_placeholder_names_example: "Ej. Luis Enrique",
    user_input_label_lastname: "Apellidos",
    user_input_placeholder_lastname_example: "Ej. Cortes López",
    user_input_label_email: "Correo electrónico",
    user_input_placeholder_email_example: "Ej. lcortes@neu.com.co",
    user_input_label_phone: "Teléfono",
    user_input_placeholder_phone_example: "Ej. 3013865253",
    user_input_label_optional_phone: "Teléfono alternativo",
    user_input_placeholder_optional_phone_example: "Ej. 3040625",
    user_input_label_address: "Dirección",
    user_input_placeholder_address_example: "Ej. Calle 27 S # 100 - 14",
    user_input_label_optional_address: "Dirección alternativa",
    user_input_placeholder_optional_address_example: "Ej. Calle 13 S # 120 - 45",
    user_select_label_language: "Selecciona un lenguaje",
    user_title_section_health: "Información Salud",
    user_input_label_height: "Altura (m)",
    user_input_placeholder_height_example: "Ej. 1.75",
    user_input_label_weight: "Peso (kg)",
    user_input_placeholder_weight_example: "Ej. 78",
    user_input_label_eps: "EPS",
    user_input_placeholder_eps_example: "Ej. Sura",
    user_select_label_insurance: "¿Tienes seguro de salud?",
    user_title_section_home: "Información Hogar",
    user_input_label_type_property: "Tipo de casa",
    user_input_placeholder_type_property: "Seleccione un tipo de inmueble",
    user_input_label_house_area: "Área (m2)",
    user_input_placeholder_house_area_example: "Ej. 70",
    user_input_label_house_people: "¿Cuántas personas viven en tu hogar?",
    user_input_label_house_levels: "¿Cuántos niveles tiene tu hogar?",
    user_input_label_house_floor: "¿Qué piso es?",
    user_picker_label_own_home: "¿Tu casa es propia?",
    user_picker_label_solar_system: "¿Tienes un sistema solar?",
    user_title_section_vehicle: "Información Automóvil",
    user_picker_label_own_vehicle: "¿Tienes tu propio vehículo?",
    user_picker_label_electric_vehicle: "¿Tu vehículo es eléctrico?",
    user_picker_label_armored_vehicle: "¿Tu vehículo es blindado?",
    user_save_button: "Guardar",
    user_cancel_button: "Cancelar",
    user_title_section_create_users: "Agregar nuevo usuario",
    user_title_section_edit_users: "Editar usuarios",
    user_title_section_accounts: "Mis cuentas",
    user_title_section_privacy_info: "Aviso de privacidad",
    user_title_section_terms_conditions: "Términos & condiciones",
    user_button_logout: "Cerrar sesión",
    user_select_option_language_es: "Español",
    user_select_option_language_en: "Inglés",
    user_select_option_type_apartment: "Apartamento",
    user_select_option_type_house: "Casa",
    user_select_option_type_country_house: "Casa Campestre",
    user_select_option_type_farm: "Finca",
    user_title_create_user: "Agregar Usuario",
    user_title_edit_user: "Editar Usuario",
    user_select_label_job_area: "Selecciona el área a la que pertenece",
    user_select_option_financial_area: "Financiera",
    user_select_option_energy_area: "Energía",
    user_select_option_support_area: "Mantenimiento",
    user_select_option_enviromental_area: "Ambiental y Sostenibilidad",
    user_select_option_other_area: "Otro",
    user_select_label_services_selected: "Servicios seleccionados",
    user_select_label_services: "Seleccione los servicios",
    user_button_add_user: "Agregar Usuario",
    user_button_edit_user: "Editar Usuario",
    users_title_main_header: "Usuarios",
    users_table_header_edit: "Editar",
    users_table_header_id_document: "Documento",
    users_table_header_name: "Nombre",
    users_table_header_lastname: "Apellido",
    users_table_header_phone: "Teléfono",
    users_table_header_email: "Correo electrónico",
    users_table_header_job_area: "Área",
    users_table_header_services: "Servicios",
    users_pagination_go_to_page: "Ir a página:",
    users_pagination_show_page: "Mostrar",
    login_main_title: "Reinventamos el futuro de la energía",
    login_title_description: "Inicia sesión con tu correo o número registrado.",
    login_input_label_data: "Correo o número de teléfono",
    login_input_label_code: "Escribe el código de verificación de 6 dígitos que te enviamos a:",
    login_question_code: "¿No recibiste el código?",
    login_await_resend_code: "Debes esperar #seg",
    login_unit_await_resend_code: "segundos",
    login_resend_code: "Reenviar un nuevo código",
    login_button_validate: "Validar",
    login_button_continue: "Continuar",
    login_button_enter: "Ingresar",
    login_link_cancel: "Cancelar",
    login_title_step_two: "Sólo un paso más...",
    plus_title_main_dashboard: "Streaming",
    plus_label_empty_data: "Aún no posees datos disponibles",
    plus_title_empty_state: "Es hora de que tomes el control de tu energía",
    plus_introduction_empty_state: "Todo el mundo necesita energía, pero sabemos que no hay dos negocios iguales. Haremos las cosas fáciles para que pueda comprender, actuar y comenzar a ahorrar dinero en su factura de energía.",
    plus_phrase_empty_state: "Te mereces algo mejor, te mereces NEU+",
    plus_button_access: "Súmale poder a tu energía",
    plus_link_more_info: "Conoce más",
    plus_graph_picker_option_active_power: "Potencia activa",
    plus_graph_picker_option_reactive_power: "Potencia reactiva",
    plus_graph_picker_option_rms_voltage_1: "Voltaje 1",
    plus_graph_picker_option_rms_voltage_2: "Voltaje 2",
    plus_graph_picker_option_rms_voltage_3: "Voltaje 3",
    plus_graph_picker_option_rms_current_1: "Corriente 1",
    plus_graph_picker_option_rms_current_2: "Corriente 2",
    plus_graph_picker_option_rms_current_3: "Corriente 3",
    plus_graph_text_help_tag_step_1: "Arrastre la línea punteada dentro de la gráfica para obtener el primer punto. Puedes marcar solo este punto o añadir un punto final con seleccionar rango.",
    plus_graph_text_help_tag_step_2: "Arrastre de nuevo la línea hasta el segundo punto, para marcar un rango de tiempo específico de la etiqueta.",
    plus_graph_text_empty_data_device: "No hemos podido encontrar respuesta del medidor. Revisa la conexión.",
    plus_graph_text_last_connection: "Últ. conexión: ",
    plus_graph_button_show_last_records_device: "Mostrar últimos datos registrados",
    plus_title_max_active_power: "Potencia activa",
    plus_unit_max_active_power: "Máx.",
    plus_graph_button_tag: "Etiquetar",
    plus_graph_button_tag_manager: "Administrar etiquetas",
    plus_graph_button_select_point: "Marcar punto",
    plus_graph_button_select_range: "Seleccionar rango",
    plus_graph_button_select_points: "Marcar y finalizar",
    plus_graph_button_go_back: "Volver",
    plus_always_on_title: "Consumo Fantasma",
    plus_trends_title: "Tendencias",
    plus_trends_label: "en comparación a la hora anterior",
    plus_radar_title: "Calidad de Energía",
    plus_rank_title: "Comparativa",
    plus_graph_title_empty_data: "No hay datos para mostrar",
    tag_new_device_main_title: "Crear Nuevo Dispositivo",
    tag_new_device_header_label_save: "Guardar",
    tag_new_device_header_label_cancel: "Cancelar",
    tag_new_device_header_label_device: "Agregar Dispositivo",
    tag_new_device_title_name: "Nombre Dispositivo",
    tag_new_device_input_label_name: "Nombre",
    tag_new_device_title_type: "Tipo de Dispositivo",
    tag_new_device_input_label_type: "Tipo de Dispositivo",
    tag_new_device_title_brand: "Marca",
    tag_new_device_input_label_brand: "Marca",
    tag_new_device_title_reference: "Referencia",
    tag_new_device_input_label_reference: "Ref.",
    tag_new_device_button_create: "Crear",
    tag_new_device_button_cancel: "Cancelar",
    tag_new_tag_header_label_save: "Guardar",
    tag_new_tag_header_label_tag: "Crear Etiquetas",
    tag_new_tag_header_label_cancel: "Cancelar",
    tag_new_tag_input_label_search: "Buscar dispositivo",
    tag_new_tag_title_devices_list: "Listado de dispositivos",
    tag_new_tag_text_average_comsuption: "Consumo promedio",
    tag_new_tag_text_duration: "Duración",
    tag_new_tag_text_no_tag: "No posee etiquetas",
    tag_new_tag_text_no_devices: "No hay dispositivos creados",
    tag_new_tag_text_no_devices_description: "Empieza a agregar tus dispositivos para vincularlos a tus etiquetas.",
    tag_new_tag_button_link_tag: "Vincular Etiqueta",
    tag_table_tag_label_processed: "Instantáneo",
    tag_table_tag_label_procesing: "Procesando...",
    tag_new_tag_button_new_device: "Nuevo Dispositivo",
    tag_graph_power_legend_active_power: "Potencia Activa",
    tag_graph_power_legend_reactive_power: "Potencia Reactiva",
    tag_graph_power_title_empty_data: "No hay datos",
    tag_graph_power_phrase_empty_data: "Empieza a etiquetar tus dispositivos para ver tus datos aquí",
    tag_manager_title_main_dashboard: "Administrar Etiquetas",
    tag_manager_title_devices: "Dispositivos",
    tag_manager_table_header_name: "Nombre",
    tag_manager_table_header_create_at: "Creación",
    tag_manager_table_header_active_energy: "Energía Activa",
    tag_manager_table_header_active_power: "Potencia Activa",
    tag_manager_table_header_reactive_energy: "Energía Reactiva",
    tag_manager_table_header_reactive_power: "Potencia Reactiva",
    tag_manager_table_title_empty_data: "No hay datos",
    tag_manager_table_phrase_empty_data: "Empieza a etiquetar tus dispositivos para ver tus datos aquí",
    tag_manager_button_tag: "Etiquetar",
    tag_title_section_new_tags: "Etiquetas Recientes",
    services_input_label_search: "Buscar ubicación",
    services_select_all: "Seleccionar todos los servicios",
    energy_main_title_dashboard: "Servicios",
    energy_invoice_show_bills: "Ver facturas",
    energy_invoice_state_paid: "Pagada",
    energy_invoice_state_to_pay: "Pagar",
    energy_invoice_no_bills: "No hay facturas para mostrar",
    energy_invoice_title: "Mis Facturas",
    energy_invoice_label_consumption: "Consumo",
    energy_invoice_label_tariff: "Tarifa",
    energy_invoice_show_detail: "Ver detalle",
    energy_trends_title: "Tendencias",
    energy_trends_title_comparative: "en comparación al mes anterior",
    energy_widgets_empty_data: "Aún no hay datos para mostrar",
    energy_adress_title: "Dirección",
    energy_service_type_title: "Tipo de Servicio",
    energy_users_title: "Usuarios",
    energy_period_picker_hour_to_hour: "Hora a Hora",
    energy_period_picker_hour_to_hour_description: "Verás un (1) día dividido en 24 horas.",
    energy_period_picker_day_to_day: "Rango semanal",
    energy_period_picker_day_to_day_description: "Verás una semana dividida en 7 días.",
    energy_period_picker_day_to_day_monthly: "Rango mensual",
    energy_period_picker_day_to_day_monthly_description: "Verás un mes dividido en 30 días.",
    energy_period_picker_month_to_month: "Rango anual",
    energy_period_picker_month_to_month_description: "Verás un año dividido en 12 meses.",
    energy_graph_calendar_fast_access: "Accesos rápidos",
    energy_graph_calendar_button: "Hecho",
    energy_graph_calendar_week_range: "Últimos 7 días",
    energy_graph_calendar_month_range: "Último mes",
    energy_graph_title_empty_data: "Todavía no hay datos disponibles para tu selección, lo estamos solucionando",
    energy_graph_description_empty_data: "Modifica la variable o el rango de fecha para visualizar los datos disponibles",
    energy_graph_empty_data_button: "Selecciona un rango de fechas",
    energy_graph_title: "Gráficas",
    energy_graph_title_consumption: "Consumo",
    energy_graph_title_generation: "Generación",
    energy_graph_title_daily_consumption: "Consumo Diario",
    energy_graph_title_weekly_consumption: "Consumo Semanal",
    energy_graph_title_monthly_consumption: "Consumo Mensual",
    energy_graph_title_year_consumption: "Consumo Anual",
    energy_graph_title_daily_generation: "Generación Diaria",
    energy_graph_title_weekly_generation: "Generación Semanal",
    energy_graph_title_monthly_generation: "Generación Mensual",
    energy_graph_title_year_generation: "Generación Anual",
    energy_graph_indicator_total: "Total",
    energy_graph_indicator_max: "Máximo",
    energy_graph_indicator_average: "Promedio",
    energy_graph_period_picker_day: "Día",
    energy_graph_period_picker_week: "Semana",
    energy_graph_period_picker_month: "Mes",
    energy_graph_period_picker_year: "Año",
    energy_graph_type_picker_search: "Buscar gráfica",
    energy_graph_type_disclaimer: "Ciertas variables no se pueden comparar entre sí por las diferencias en unidad de medida",
    energy_graph_group_reactive: "Reactiva",
    energy_graph_type_average_line: "Promedio (en gráfica)",
    energy_graph_group_ac: "AC",
    energy_graph_group_irradiance: "Irradiancia",
    energy_graph_group_transactive_energy: "Transacciones",
    energy_graph_group_power: "Potencia",
    energy_graph_group_active_energy_cost: "Costo de la energía",
    energy_graph_group_dc: "DC",
    energy_graph_group_generation: "Generación",
    energy_graph_group_current: "Corriente",
    energy_graph_group_frequency: "Frecuencia",
    energy_graph_group_active_energy: "Consumo",
    energy_graph_group_metereology: "Meteorología",
    energy_graph_group_injection: "Inyección",
    energy_graph_group_voltage: "Voltaje",
    energy_graph_group_temperature: "Temperatura",
    energy_graph_group_irradiance_gen: "Irradiancia / Generación.",
    energy_graph_group_operation_state: "Estado de operación",
    energy_graph_group_prediction_active_energy: "Predicción de consumo",
    energy_graph_label_active_energy: "Consumo",
    energy_graph_label_injected_energy: "Inyección",
    energy_graph_label_transactive_energy: "Transacciones",
    energy_graph_label_reactive_excess_energy: "Reactiva en exceso",
    energy_graph_label_reactive_inductive_energy: "Reactiva inductiva",
    energy_graph_label_reactive_capacitive_energy: "Reactiva capacitiva",
    energy_graph_label_current: "Corriente",
    energy_graph_label_voltage: "Voltaje",
    energy_graph_label_frequency: "Frecuencia",
    energy_graph_label_generation: "Generación",
    energy_graph_label_generation_spec: "Generación especifica",
    energy_graph_label_current_ac: "Corriente AC",
    energy_graph_label_current_dc: "Corriente DC",
    energy_graph_label_voltage_ac: "Tensión AC",
    energy_graph_label_voltage_dc: "Tensión DC",
    energy_graph_label_active_pwr: "Potencia activa",
    energy_graph_label_apparent_pwr: "Potencia aparente",
    energy_graph_label_pwr_factor: "Factor de potencia",
    energy_graph_label_inverter_temp: "Temperatura del inversor",
    energy_graph_label_heatsink_temp: "Temperatura del disipador",
    energy_graph_label_irradiance: "Irradiancia",
    energy_graph_label_panel_temp: "Temperatura del panel",
    energy_graph_label_environment_temp: "Temperatura ambiente",
    energy_graph_label_humidity: "Humedad",
    energy_graph_label_wind_speed: "Velocidad del viento",
    energy_graph_label_wind_direction: "Dirección del viento",
    energy_graph_label_gen_frequency: "Frecuencia",
    energy_graph_label_active_energy_cost: "Costo de la energía",
    energy_graph_label_active_power: "Potencia activa",
    energy_graph_label_reactive_power: "Potencia reactiva",
    energy_graph_label_prediction_active_energy: "Predicción de consumo",
    energy_graph_label_reactive_inductive_excess_energy: "Reactiva inductiva en exceso",
    energy_graph_label_reactive_capacitive_excess_energy: "Reactiva capacitiva en exceso",
    energy_graph_unit_active_energy: "kWh",
    energy_graph_unit_injected_energy: "kWh",
    energy_graph_unit_transactive_energy: "kWh",
    energy_graph_unit_reactive_excess_energy: "kVArh",
    energy_graph_unit_reactive_inductive_energy: "kVArh",
    energy_graph_unit_reactive_capacitive_energy: "kVArh",
    energy_graph_unit_reactive_inductive_excess_energy: "kVArh",
    energy_graph_unit_reactive_capacitive_excess_energy: "kVArh",
    energy_graph_unit_current: "Amp",
    energy_graph_unit_voltage: "Vol",
    energy_graph_unit_frequency: "Hz",
    energy_graph_unit_generation: "kWh",
    energy_graph_unit_generation_spec: "kWh/kWp",
    energy_graph_unit_current_ac: "Amp",
    energy_graph_unit_current_dc: "Amp",
    energy_graph_unit_voltage_ac: "Vol",
    energy_graph_unit_voltage_dc: "Vol",
    energy_graph_unit_active_pwr: "kW",
    energy_graph_unit_apparent_pwr: "kVA",
    energy_graph_unit_pwr_factor: "",
    energy_graph_unit_inverter_temp: "°C",
    energy_graph_unit_heatsink_temp: "°C",
    energy_graph_unit_irradiance: "W/m2",
    energy_graph_unit_panel_temp: "°C",
    energy_graph_unit_environment_temp: "°C",
    energy_graph_unit_humidity: "%",
    energy_graph_unit_wind_speed: "m/s",
    energy_graph_unit_wind_direction: "grados",
    energy_graph_unit_gen_frequency: "Hz",
    energy_graph_unit_active_energy_cost: "COP",
    energy_graph_unit_active_power: "W",
    energy_graph_unit_reactive_power: "W",
    energy_graph_unit_prediction_active_energy: "kWh",
    invoices_main_title_dashboard: "Facturas",
    invoices_main_title: "Mis Facturas",
    invoices_state_pending: "Pendiente",
    invoices_state_overdue: "Vencida",
    invoices_state_paid: "Pagada",
    invoices_state_suspended_service: "Suspensión",
    invoices_state_due_date: "Vence",
    invoices_button_download_bills: "Descargar Facturas",
    invoices_text_selec_all: "Seleccionar todas",
    invoices_text_total_pay: "Pago total",
    invoices_select_placeholder_all_services: "Todos los servicios",
    invoices_button_pay: "Pagar",
    invoices_text_empty_bills_period: "No hay facturas disponibles para este periodo",
    invoices_title_section_bills: "Factura",
    invoices_text_error_show_bills: "No fue posible mostrar tu factura, por favor intenta más tarde!",
    invoices_text_no_select_bills: "No hay facturas seleccionadas",
    invoices_pay_title_section_personal_data: "Datos personales",
    invoices_pay_title_section_bank_data: "Datos bancarios",
    invoices_pay_text_modal: "Facturas",
    invoices_pay_input_label_name: "Nombres",
    invoices_pay_input_placeholder_user_name_example: "Ej. Luis Enrique",
    invoices_pay_input_label_email: "Email",
    invoices_pay_input_placeholder_email_example: "Ej. lcortes@neu.com.co",
    invoices_pay_input_label_phone: "Teléfono",
    invoices_pay_input_placeholder_phone_example: "Ej. 3126160897",
    invoices_pay_input_label_address: "Dirección",
    invoices_pay_input_placeholder_address_example: "Ej. Calle 27 S # 100 - 14",
    invoices_pay_input_label_type_person: "Tipo de persona",
    invoices_pay_input_label_type_document: "Tipo de documento",
    invoices_pay_input_label_idDocument: "Documento de identidad",
    invoices_pay_input_placeholder_idDocument_example: "Ej. 1040045234",
    invoices_pay_input_label_bank: "Entidad bancaria",
    invoices_pay_title_total_payment: "Pago total",
    invoices_pay_title_subtotal_payment: "Subtotal",
    invoices_pay_title_use_neugets: "¿Usar tus NEUGets?",
    invoices_pay_title_get_neugets: "¡Conoce cómo obtener neugets!",
    invoices_pay_title_quantity_neugets: "¿Cuántos NEUGets quieres usar?",
    invoices_pay_title_total: "Total",
    invoices_pay_select_label_user_type: "Selecciona el tipo de persona",
    invoices_pay_select_label_banks: "Selecciona el banco",
    invoices_pay_select_label_user_legal_type: "Selecciona el tipo de documento",
    invoices_pay_text_total_to_pay: "Total",
    invoices_pay_text_options_to_pay: "Pago seguro desde cuenta de ahorros o corriente vía",
    invoices_pay_button_pay: "Continuar con el pago",
    invoices_pay_label_option_cc: "Cédula de ciudadanía",
    invoices_pay_label_option_ce: "Cédula de extranjería",
    invoices_pay_label_option_nit: "NIT",
    invoices_pay_label_option_natural_user: "Natural",
    invoices_pay_label_option_legal_user: "Jurídica",
    invoices_card_payment_cardholder_address: 'Dirección',
    invoices_paid_title_state_approved: "Gracias, tu pago ha sido aprobado.",
    invoices_paid_title_state_declined: "Lo sentimos, tu pago ha sido rechazado.",
    invoices_paid_title_state_pending: "Tu pago aún esta pendiente.",
    invoices_paid_text_email: "Correo electrónico",
    invoices_paid_text_date: "Fecha",
    invoices_paid_text_date_payment: "Fecha de pago",
    invoices_paid_text_ref: "Referencia",
    invoices_paid_text_description: "Descripción",
    invoices_paid_text_total_payment: "Pago total",
    invoices_paid_title_payment_receipt: "Comprobante de pago",
    invoices_paid_text_transaction_details: "Detalles de la transacción",
    invoices_paid_text_total_bill: "Total Factura",
    invoices_paid_text_neugets_discount: "Descuento NEUGets",
    invoices_paid_text_neugets_remaining: "NEUGets restantes",
    invoices_paid_button_print: "Imprimir",
    invoices_paid_link_finally: "Finalizar",
    invoices_summary_title_energy: "Energía",
    invoices_summary_title_plus: "Plus",
    invoices_summary_title_zero: "Zero",
    invoices_summary_title_detail_bill: "Detalle",
    invoices_summary_title_total_bill: "Total",
    invoices_summary_title_quantity_bill: "Cantidad",
    invoices_summary_title_unit_price: "Precio unitario",
    invoices_alert_overdue: "Recuerda: Las facturas vencidas son objeto de intereses y desconexión.",
    invoices_alert_note: "¡Evita caer en mora!",
    alert_user_popup_name: "Debes ingresar un nombre válido.",
    alert_user_popup_lastname: "Debes ingresar un apellido válido.",
    alert_user_popup_id_document: "Debes ingresar un documento de identidad válido.",
    alert_user_popup_email: "Debes ingresar un correo eléctronico válido.",
    alert_create_user_popup_email: "Debes ingresar un email válido, ten en cuenta que este se usará para el ingreso a la plataforma.",
    alert_user_popup_phone: "Debes ingresar un teléfono válido.",
    alert_create_user_popup_phone: "Debes ingresar un celular válido, ten en cuenta que este se usará para el ingreso a la plataforma.",
    alert_create_user_popup_job_area: "Debes ingresar un área de trabajo válida.",
    alert_create_user_popup_selected_services: "Debes seleccionar los servicios a los que tendrá acceso.",
    alert_new_user_popup_success: "¡Genial! Se creó exitosamente el usuario, ahora mas personas tendrán control de la energía.",
    alert_edit_user_popup_success: "¡Genial! Se actualizó exitosamente el usuario.",
    alert_new_user_popup_failed: "¡Ups! ocurrió un error creando el usuario, por favor intente mas tarde.",
    alert_edit_user_popup_failed: "¡Ups! ocurrió un error actualizando el usuario, por favor intente mas tarde.",
    alert_user_popup_address: "Debes ingresar una dirección válido.",
    alert_support_popup_ticket_service: "Por favor selecciona un servicio.",
    alert_support_popup_ticket_description: "Por favor describe tu solicitud.",
    alert_support_popup_success: "¡Muchas gracias!, nuestro equipo te contactará cuanto antes.",
    alert_submit_form_popup_success: "¡Muchas gracias!, los datos se han cargado correctamente.",
    alert_submit_form_popup_failed: "¡Ups! Ha ocurrido un error, por favor intenta de nuevo en unos minutos o comunícate con nosotros.",
    alert_pay_popup_banks: "Selecciona la entidad bancaria",
    alert_title_error: "¡Ups! datos inválidos",
    alert_title_success: "¡Los datos son válidos",
    alert_shared_error: "¡Ups!, Ha ocurrido un error, porfavor vuelve a intentarlo.",
    alert_login_user_not_found: "Aún no estas registrado.",
    alert_login_invalid_code: "El código es inválido.",
    menu_main_dashboard: "Resumen",
    menu_energy_service: "Energy",
    menu_zero_service: "Zero",
    menu_efficiency_service: "Efficiency",
    menu_plus_service: "Plus",
    menu_onboarding: "Instalaciones",
    main_title_mobile_screen: "Reinventamos el futuro de la energía",
    main_description_mobile_screen: "Para una mejor experiencia, descarga la aplicación en tu celular. Encuéntrala en la tienda de tu dispositivo.",
    language_locale: "es",
    account_title_main_dashboard: "Mis Cuentas",
    account_detail_title_admin: "Administradores",
    account_detail_title_relations: "Relacionado con",
    service_detail_title_users: "Miembros",
    service_detail_title_location: "Ubicación",
    zero_credit_card_title_show_detail: "Ver detalle",
    zero_credit_card_title_photovoltaic_system: "Sistema fotovoltaico",
    zero_credit_card_title_full_loan: "Préstamo total",
    zero_credit_card_title_next_payment: "Próximo pago",
    zero_credit_detail_state_not_available: "No disponible",
    zero_credit_detail_state_pending: "Pendiente",
    zero_credit_detail_state_paid: "Pagada",
    zero_credit_detail_state_canceled: "Cancelada",
    zero_credit_detail_state_overdue: "Vencida",
    zero_credit_detail_title_detail_credit: "Detalle de tu crédito",
    zero_credit_detail_title_fee_amount: "Número de cuotas",
    zero_credit_detail_title_agreed_interest_rate: "Interés pactado",
    zero_credit_detail_rate_placement: "E.A",
    zero_credit_detail_title_full_loan: "Préstamo total",
    zero_credit_detail_title_total_paid: "Total pagado",
    zero_credit_detail_title_depreciation_table: "Tabla de amortización",
    zero_marketplace_title_main: "Adquiere un nuevo crédito para impulsar tu negocio",
    zero_marketplace_description: "Queremos que tu negocio siga creciendo sin límites, por eso te pre-aprobamos un crédito desde",
    zero_marketplace_title_contact_adviser: "Contactar un asesor",
    zero_marketplace_title_credit_uses: "Usa tu crédito para",
    zero_service_title_suggested: "Recomendado",
    zero_service_detail_empty_content: "No hay contenido para mostrar...",
    zero_service_detail_button_request_quote: "Solicitar cotización",
    zero_approve_quote_title_main: "¡Genial, nos encanta que encontraste lo que estabas buscando! 🥳 🥳",
    zero_approve_quote_title_question: "¿Ahora qué sigue?",
    zero_approve_quote_info_email: "Te enviamos la informaciòn para continuar el proceso al correo",
    zero_approve_quote_button_continue: "Continuar",
    zero_quote_title_version: "Versión",
    zero_quote_title_show_detail: "Ver detalle",
    zero_quote_title_valid_until: "Válido hasta",
    zero_quote_button_approve_quote: "Aprobar cotización",
    zero_quote_state_in_process: "En proceso...",
    zero_quote_state_accepted: "Propuesta aceptada",
    zero_quotes_title_main: "Cotizaciones",
    zero_quotes_empty_state: "Aún no tienes cotizaciones",
    zero_quotes_empty_state_description: "¡No lo pienses más! Contacta a un asesor ahora mismo y resuelve tus dudas sobre cómo invertir más en tu negocio.",
    zero_tab_label_home: "Inicio",
    zero_tab_label_quotes: "Cotizaciones",
    zero_tab_label_wallet: "Billetera",
    zero_neugets_card_title_balance: "Tu Balance",
    zero_neugets_card_title_help: "¿Qué son los NEUgets?",
    zero_neugets_card_title_neugets: "NEUgets",
    zero_neugets_card_title_ranking: "Ranking",
    zero_neugets_card_title_ranking_card: "Energy Master",
    zero_wallet_title_history: "Historial",
    zero_transactions_redeemed_neugets: "Neugets redimidos.",
    zero_transactions_acquired_neugets: "Neugets adquiridos.",
    zero_transactions_invoice_paid: "Pago factura de energía",
    zero_transactions_empty_state: "No hay historial",
    zero_transactions_empty_state_description: "Aún no cuentas con un historial de transacciones.",
    loader_text_content: "Cargando...",
    invoices_text_empty_description_bills_period: "Espera el periodo de facturación para poder ver tu factura, o selecciona otro mes para ver tus facturas ya pagadas.",
    alert_create_preference_info_user_success: "Tu respuesta ha sido enviada",
    alert_create_preference_info_user_error: "Lo sentimos, ha ocurrido un error",
    alert_users_info_reception: "Debes seleccionar como prefieres recibir la información",
    alert_users_platform_uses: "Escribe por favor que buscas en la plataforma",
    alert_users_personal_interests: "Debes seleccionar en que se enfocan tus intereses",
    users_preference_info_user_title: "¡Queremos brindarte información de valor!",
    users_preference_info_user_description: "Por eso quisieramos conocer más de ti y de tu empresa para ofrecerte información personalizada según tus requerimientos.",
    users_preference_info_user_job: "1. ¿En qué área ejerces tu rol en la empresa?",
    users_preference_info_reception: "2. ¿Como prefieres recibir información?",
    users_preference_info_user_platform_uses: "3. ¿Para qué quieres utilizar la plataforma? ¿qué información quieres ver en la app?",
    users_preference_info_user_interests: "4. Tus intereses se enfocan en...",
    users_multipleselect_placeholder: "Selecciona una o más opciones",
    users_input_label_platform: "¿Qué buscas encontrar en la plataforma?",
    users_button_personal_preferences: "Aceptar y Enviar",
    alert_delete_user_success: "Se ha eliminado correctamente el usuario",
    alert_delete_user_error: "No fue posible eliminar el usuario",
    users_multipleselect_label_selected: "Items seleccionados",
    alert_users_preference_info: "Por favor completa el formulario para continuar",
    users_preference_info_title: "¡Ayúdanos a completar tu perfil!",
    users_preference_info_description: "¡Bienvenido a la Plataforma de NEU! Para poder acceder a todos los beneficios, completa tus datos de perfil y lleva tu energía a otro nivel",
    users_preference_info_button_complete: "Completar perfil ",
    neugets_tooltip_copy_code: "Código copiado",
    neugets_text_share_code_title: "Comparte y gana con NEU",
    neugets_text_share_code_message: "Hola\n¿Te quieres ganar un super descuento en tu próxima factura de energía?\n\n¡Te invito a que conozcas a NEU Energy! El único proveedor de energía eléctrica en Colombia que te permite visualizar tu consumo en tiempo real desde tu celular 📊📱 y reducir tu factura de energía con notificaciones de eficiencia energética.⚡\n\nRegístrate con mi código de referido: \n\n#code#\n\nAmbos participaremos para obtener descuentos en futuras facturas de energía eléctrica.🧾⚡\n\nRegístrate en: \n\nhttps://www.neu.com.co/registro",
    neugets_drawer_main_title: "Tus NEUGets",
    neugets_drawer_text_neugets_description: "Los NEUGets (Green energy tokens) son nuestro token virtual en NEU y una unidad equivale a $1.000 COP.",
    neugets_drawer_text_use_neugets: "Podrás usar los NEUGets dentro de nuestra plataforma para pagar tus recibos e incluso adquirir más productos y servicios.",
    neugets_drawer_text_get_neugets: "¡Empieza a acumular más NEUGets refiriendo a otras empresas que quieran pasarse a NEU!",
    neugets_drawer_text_share_referral_code: "¡Comparte este código con tus contactos y una vez se registre, ambos ganarán NEUGets!",
    neugets_drawer_text_legal_terms: "*Aplican términos y condiciones",
    alert_create_new_tag_success: "La etiqueta se ha creado con éxito",
    alert_create_new_tag_failed: "Ha ocurrido un error al crear la etiqueta",
    alert_create_new_device_success: "El dispositivo se ha creado con éxito",
    alert_create_new_device_failed: "Ha ocurrido un error al crear el dispositivo",
    alert_users_failed_change_image: "Ha ocurrido un error cargando la imagen, por favor intente mas tarde.",
    energy_export_graph_title: "Exportar gráfica",
    energy_export_graph_start_time: "Fecha de Inicio",
    energy_export_graph_end_time: "Fecha Final",
    energy_export_graph_filename: "Nombre del archivo",
    energy_export_graph_button: "Exportar CSV",
    alert_download_report_error: "Ha ocurrido un error. Intente más tarde!",
    alert_filename_report_error: "Nombre de archivo inválido.",
    menu_genius_service: "Genius",
    daily_active_energy: "Consumo diario por sede",
    weekly_active_energy: "Consumo semanal por sede",
    hourly_distribution_active_energy: "Distribución del consumo horario",
    money_savings_history: "Histórico de ahorros mensuales por sede",
    reactive_excess_history: "Histórico de energía reactiva en exceso",
    reactive_excess_weekly: "Energía reactiva en exceso semanal",
    hourly_active_energy: "Consumo horario por sede",
    monthly_active_energy: "Consumo mensual por sede",
    weekly_mean_active_energy: "Consumo medio semanal",
    reactive_excess_daily: "Energía reactiva en exceso promedio diaria",
    alert_genius_create_report_filename: "Define un nombre para el reporte por favor",
    alert_genius_create_report_graphs: "Selecciona al menos un tipo de gráfica",
    alert_genius_create_report_devices: "Selecciona al menos un servicio",
    alert_genius_create_report_range: "Selecciona el rango de fecha para el reporte",
    genius_drawer_title_main: "Crear Reporte",
    genius_input_label_filename: "Nombre del reporte",
    genius_input_placeholder_filename_example: "Ej. Reporte 001",
    genius_multipleselect_label_graphs: "Gráficas del reporte",
    genius_multipleselect_placeholder_graphs_selected: "Gráficas seleccionadas",
    genius_multipleselect_placeholder_select: "Selecciona una o más opciones",
    genius_multipleselect_label_devices: "Dispositivos",
    genius_multipleselect_placeholder_devices_selected: "Dispositivos seleccionados",
    genius_calendar_label_date: "Fecha",
    genius_calendar_placeholder_select: "Selecciona el rango de fecha",
    genius_button_create_report: "Generar reporte",
    genius_button_cancel_report: "Cancelar",
    genius_title_main_reports: "Reportes",
    genius_button_new_report: "Crear nuevo reporte",
    genius_header_title_report_premium: "Smart Report",
    genius_header_title_report_basic: "Cómo Vamos",
    genius_title_empty_state: "No hay reportes para mostrar",
    genius_text_description_empty_state: "Genera un nuevo reporte desde la parte superior de este módulo para visualizarlo aquí.",
    genius_no_reports: "No hay reportes disponibles",
    genius_new_report: "Nuevo Reporte",
    genius_delete_report: "Eliminar",
    genius_download_report: "Descargar",
    genius_save_report: "Crear reporte",
    genius_cancel_report: "Cancelar",
    genius_name_report: "Nombre",
    genius_date_range_report: "Rango de fecha",
    genius_creation_date_report: "Fecha de creación",
    genius_services_report: "Servicios",
    genius_selected_items: "seleccionados",
    genius_add_services: "Agregar servicios",
    genius_done_button: "Hecho",
    genius_graphs: "Gráficas",
    genius_add_graphs: "Agregar las gráficas",
    genius_new_item: "¡Nueva!",
    genius_search_placeholder: "Buscar",
    alert_genius_create_report_success: "El reporte se ha generado con éxito",
    alert_genius_create_report_pending: "El reporte puede tardar un momento en crearse, por favor espere y recargue la página de nuevo!",
    alert_genius_create_report_failed: "Algo fallo, por favor intenta de nuevo!",
    alert_create_new_tag_associated_tags: "Se ha vinculado la etiqueta con éxito a este dispositivo",
    shared_multipleselect_label_select_all: "Seleccionar todo",
    invoices_text_unselected_all: "Deseleccionar todas",
    plus_modal_title_empty_state: "Desbloquea todo el potencial de tu energía con NEU+.",
    plus_modal_description_empty_state: "NEU+ te brinda herramientas que te permitirán tener reportes y gráficas segundo a segundo, conocer a detalle tus consumos en exceso y tomar acciones justo cuando suceden las cosas.",
    plus_modal_phrase_empty_state: "¡No esperes más!",
    plus_modal_button_empty_state: "Contactar a un asesor",
    plus_rank_tooltip_info: "Proyectos que, a la fecha, han tenido un mayor consumo acumulado mensual.",
    plus_always_on_tooltip_info: "Consumo de energía que se da por fuera de tu operación a causa de las máquinas o equipos que permanecen conectados.",
    plus_radar_tooltip_info_voltage: "Voltaje promedio de la última hora en cada fase (se muestra en voltios)",
    plus_radar_tooltip_info_current: "Corriente promedio de la última hora en cada fase (se muestra en Amperios)",
    plus_radar_tooltip_info_power: "Potencia activa promedio de la última hora en cada fase (se muestra en Watts)",
    genius_button_default_report: "Crear mi primer reporte",
    energy_graph_message_scroll_active: "Para moverte dentro de la gráfica haz clic en el cursor y muévete hacia la dirección deseada.",
    user_table_title_empty_state: "No hay usuarios vinculados a tus servicios",
    user_table_description_empty_state: "Agrega usuarios desde el menú de perfil  y dale acceso a los servicios vinculados en tu cuenta de NEU",
    alert_support_popup_type_ticket: "Selecciona por favor el tipo de ticket",
    alert_support_popup_area_ticket: "Selecciona por favor el área del ticket",
    support_select_placeholder: "Selecciona una opción",
    plus_export_graph_title: "Exportar gráfica",
    plus_export_graph_date: "Fecha",
    plus_export_graph_filename: "Nombre del archivo",
    plus_export_graph_format: "Formato",
    plus_export_graph_button: "Exportar",
    alert_format_filename_report_error: "Tipo de archivo inválido",
    alert_date_report_error: "Fecha inválida",
    alert_new_device_name: "Escribe el nombre del dispositivo",
    alert_new_device_type: "Escribe el tipo de dispositivo",
    money_savings_table: "Histórico de ahorros mensuales por sede (tabla)",
    alert_genius_create_report_duplicate_filename: "El nombre del reporte ya existe",
    shared_survey_nps_title: "Califica tu experiencia NEU",
    shared_survey_nps_text_question: "Califica tu experiencia con NEU de 1 a 10. Con 1 entenderemos que tenemos mucho por mejorar pero con 10 que todo está saliendo bien con el trabajo realizado.",
    shared_survey_nps_text_comment: "Si deseas, puedes dejarnos más comentarios aquí:",
    shared_survey_nps_text_secondary_title: "¡Gracias por calificarnos!",
    shared_survey_nps_text_description: "Tu opinión es muy importante para nosotros 😁 y nos llena de buena energía saber que estás feliz con NEU ⚡️",
    shared_survey_nps_text_question_bad: "¿Qué sería lo que mejorarías del servicio?",
    shared_survey_nps_text_question_good: "¿Qué sería lo que más resaltarías del servicio?",
    shared_survey_nps_text_specific_question_bad: "¿Qué otra cosa mejorarías del servicio?",
    shared_survey_nps_text_specific_question_good: "¿Qué otra cosa resaltarías del servicio?",
    shared_survey_nps_list_option_bill: "Facturación 🧾",
    shared_survey_nps_list_option_platform: "Plataforma 🖥️",
    shared_survey_nps_list_option_customer: "Servicio al cliente 🧑🏻‍💻",
    shared_survey_nps_list_option_pay: "Proceso de pago 💵",
    shared_survey_nps_list_option_other: "Otro...",
    shared_survey_nps_button_send_score: "Enviar calificación",
    shared_survey_nps_button_send: "Enviar y finalizar",
    alert_nps_survey_create_success: "Tu respuesta ha sido enviada con éxito",
    alert_nps_survey_create_error: "Algo salió mal",
    premium_measurement: "Composición de las submediciones",
    premium_monthly_consumption: "Registro mensual de submedidores",
    premium_weekly_consumption: "Registro semanal de submedidores",
    premium_daily_consumption: "Registro diario de submedidores",
    user_title_section_api_token: "Token API",
    user_button_generate_token: "Generar token",
    user_button_cancel_token: "Cancelar",
    alert_generate_token_api_error: "Algo salió mal, intenta de nuevo",
    user_channel_option_email: "Correos",
    user_channel_option_calls: "Llamadas",
    user_channel_option_text: "Mensajes de texto",
    user_channel_option_wsp: "Whatsapp",
    user_channel_option_app: "Prefiero buscar información autónomamente",
    user_channel_option_myself: "Notificaciones por la App",
    user_department_option_not_apply: "N/A",
    user_department_option_manager: "Gerente / Administrativo",
    user_department_option_operative: "Operativo / Mantenimiento",
    user_department_option_financial: "Financiero",
    user_department_option_innovation: "Innovación / Sostenibilidad",
    user_department_option_tech: "Tecnología",
    user_department_option_engineer: "Ingeniería",
    user_department_option_commercial: "Comercial",
    user_interest_option_innovation: "Temas de Innovación, creatividad y emprendimiento",
    user_interest_option_environment: "Sostenibilidad y medio ambiente",
    user_interest_option_finance: "Finanzas/ números",
    user_interest_option_relationship: "Emocional/ Relacionamiento",
    energy_indicator_day_highest_consumption: "Día de Mayor Consumo",
    energy_indicator_average_consumption: "#VALUE kWh de consumo promedio",
    energy_indicator_consumption: "Consumo (#VALUE)",
    energy_indicator_compare_last_period: "#VALUE kWh vs. último periodo",
    energy_indicator_hour_highest_consumption: "Hora de Mayor Consumo",
    energy_indicator_current_consumption: "Cons. a la fecha",
    energy_indicator_reactive_energy: "Energía Reactiva",
    generation_indicator_saving: "Total ahorrado (#VALUE)",
    generation_indicator_saving_vs: "vs energía convencional",
    generation_indicator_generated: "Generación (#VALUE)",
    generation_indicator_compare_last_period: "#VALUE kWh vs. último periodo",
    generation_indicator_trees_planted: "Mes anterior #VALUE árboles",
    generation_indicator_trees_accum: "Árboles sembrados a hoy",
    generation_indicator_trees_last_period: "#VALUE árboles vs. periodo anterior",
    generation_indicator_co2_reduced: "Mes anterior #VALUE",
    generation_indicator_co2_last_period: "#VALUE tons CO2 vs. periodo anterior",
    generation_indicator_co2_reduced_accum: "Toneladas de CO2 reducidas a hoy",
    generation_indicator_highest_hour: "Hora de mayor generación",
    generation_indicator_average: "#VALUE kWh de generación promedio",
    generation_indicator_least_hour: "Hora de menor generación",
    generation_indicator_highest_day: "Día de Mayor Generación",
    generation_indicator_highest_month: "Mes de mayor generación",
    generation_indicator_highest_month_generation: "Generación: #VALUE",
    generation_indicator_least_day: "Día de Menor Generación",
    generation_indicator_current_generation: "Gen. a la fecha",
    api_user_token_tooltip_empty: "Debes generar un nuevo token",
    generation_indicator_total_generated: "Generación de energía total",
    generation_indicator_from_beginning: "Desde el inicio de operación del sistema",
    generation_indicator_count_services: "De #VALUE servicios seleccionados",
    generation_indicator_total_saving: "Ahorro de dinero total",
    genelogin_button_validateration_indicator_vs_conventional_energy: "vs energía convencional",
    generation_indicator_month_generated: "Generación mes anterior (#VALUE)",
    generation_indicator_theoretical_energy: "Energía teórica: #VALUE",
    generation_indicator_average_saving: "Promedio de ahorro mensual",
    generation_indicator_last_x_months: "En los últimos #VALUE meses",
    generation_indicator_last_month_saving: "Ahorro mes anterior (#VALUE)",
    generation_indicator_saving_tariff: "Calculado con tu tarifa de $#VALUE / kWh",
    alerts_label_no_name: "Alerta ",
    alerts_label_main_title_drawer: "Tus alertas",
    alerts_label_creation_indications: "Crea una alerta según los parámetros de seguimiento de variables de energía",
    alerts_button_add_new_alert: "Agregar alerta",
    alerts_label_recently_created: "Creadas Recientemente",
    alerts_label_title_empty_state: "No hay alertas creadas para tus servicios en este momento",
    alerts_label_body_empty_state: "Agrega una nueva alerta haciendo click en el botón.",
    alerts_condition_greater_than: "Mayor que",
    alerts_condition_same_as: "Igual que",
    alerts_condition_less_than: "Menor que",
    alerts_channel_email: "Correo",
    alerts_channel_text_message: "Mensaje de texto",
    alerts_channel_notification: "Notificación",
    alerts_label_title_edit_alert: "Editar Alerta",
    alerts_label_title_new_alert: "Nueva Alerta",
    alerts_button_save: "Guardar",
    alerts_label_body_new_alert: "Modifica los parámetros de tu alerta y realiza el seguimiento de la energía de tus servicios",
    alerts_messager_alert_create_success: "La alerta se ha creado exitosamente, accede a todas tus alertas y su configuración desde tu perfil",
    alerts_messager_alert_create_error: "Ha ocurrido un error al crear la alerta",
    alerts_messager_alert_edit_success: "La alerta se ha editado exitosamente",
    alerts_messager_alert_edit_error: "Ha ocurrido un error al editar la alerta",
    alerts_messager_alert_delete_success: "La alerta se ha eliminado exitosamente",
    alerts_messager_delete_title_confirmation: "¿Eliminar la alerta?",
    alerts_messager_delete_confirmation: "Esta acción borrará la alerta de la plataforma completamente y no se podrá recuperar.",
    alerts_messager_keep_option: "No, mantenerla",
    alerts_messager_delete_option: "Si, eliminarla",
    alerts_select_days_monday: "Lunes",
    alerts_select_days_tuesday: "Martes",
    alerts_select_days_wednesday: "Miércoles",
    alerts_select_days_thursday: "Jueves",
    alerts_select_days_friday: "Viernes",
    alerts_select_days_saturday: "Sábado",
    alerts_select_days_sunday: "Domingo",
    alerts_select_interval_hourly: "Horario",
    alerts_select_interval_daily: "Diario",
    alerts_select_interval_weekly: "Semanal",
    alerts_select_interval_monthly: "Mensual",
    alert_genius_create_report_initially: "El reporte ha empezado a generarse",
    invoice_content_text_bill: "Factura",
    invoice_content_text_contract: "Contrato",
    invoice_content_text_niu: "NIU",
    invoice_content_text_customer_name: "Facturado a",
    invoice_content_text_service_name: "Sede",
    invoice_content_text_billing_address: "Ubicación de facturación",
    invoice_content_text_total: "Total a pagar",
    invoice_content_text_bill_date: "Emisión",
    invoice_content_text_due_date: "Vencimiento",
    invoice_content_text_suspension_date: "Suspensión del servicio a partir de",
    invoice_content_text_suspension_date_empty: "Recuerda que acumular dos facturas vencidas puede dar lugar a la suspensión del servicio",
    invoice_content_text_strong_balance: "¡Bien hecho!",
    invoice_content_first_text_balance: "Estas al día con el pago de tus facturas. Hasta hoy, no tienes deudas",
    invoice_content_second_text_balance: "en tu factura de energía y eso nos llena de felicidad. ¡Gracias por tu compromiso!",
    invoice_content_text_strong_outstanding_balance: "Tienes un saldo pendiente de pago",
    invoice_content_text_outstanding_balance: "por",
    invoice_content_text_outstanding_balance_suspension: "Servicio",
    invoice_content_text_consumption_detail: "Detalle de tu consumo",
    invoice_content_text_consumption: "Consumo",
    invoice_content_text_days_billed: "Días facturados",
    invoice_content_text_billed_since: "Desde",
    invoice_content_text_billed_until: "Hasta",
    invoice_content_text_meter_code: "Código medidor",
    invoice_content_text_previous_consumption: "Lectura anterior",
    invoice_content_text_current_consumption: "Lectura actual",
    invoice_content_text_avg_six_months: "Promedio 6 meses",
    invoice_content_text_label_pay_month: "Cobro del mes (COP)",
    invoice_content_text_label_consumption: "Consumo (kWh)",
    invoice_content_text_reactive_detail: "Detalles reactiva en exceso (kVArh)",
    invoice_content_text_days_billed_reactive: "Número de días",
    invoice_content_text_amount_billed_reactive_inductive: "Inductiva",
    invoice_content_text_amount_billed_reactive_capacitive: "Capacitiva",
    invoice_content_text_amount_billed_reactive: "Total",
    invoice_content_text_reactive_factor: "Factor M",
    invoice_content_text_tariff_detail: "¿Cómo se calcula tu tarifa?",
    invoice_content_first_text_tariff: "Tu tarifa del",
    invoice_content_first_text_strong_tariff: "Mercado Regulado se denominada CU (Costo Unitario)",
    invoice_content_second_text_tariff: "y se calcula sumando cada uno de las componentes de la tárifa presentados a continuación, teniendo en cuenta que perteneces al",
    invoice_content_second_text_strong_tariff: "Mercado Regulado",
    invoice_content_third_text_tariff: "conectado a un",
    invoice_content_third_text_strong_tariff: "Nivel de tensión",
    invoice_content_fourth_text_tariff: "en",
    invoice_content_fourth_text_strong_tariff: "Estrato",
    invoice_content_fifth_text_tariff: "con propiedad de los activos",
    invoice_content_sixth_text_tariff: "y una tarifa",
    invoice_content_text_tariff_g: "Generación",
    invoice_content_text_tariff_t: "Transmisión",
    invoice_content_text_tariff_d: "Distribución",
    invoice_content_text_tariff_cv: "Comercialización",
    invoice_content_text_tariff_pr: "Perdidas",
    invoice_content_text_tariff_r: "Restricciones",
    invoice_content_text_tariff_cu: "Tarifa (CU)",
    invoice_content_text_tariff_cu_cot: "Tarifa (CU + COT)",
    invoice_content_text_technical_info: "Información técnica",
    invoice_content_text_or: "Operador de red",
    invoice_content_text_or_phone: "Teléfono",
    invoice_content_text_distr_circuit: "Circuito",
    invoice_content_text_transf_number: "Código transformador",
    invoice_content_text_legal_info: "Información legal",
    invoice_content_first_text_legal_info: "La presente factura presenta mérito ejecutivo en virtud del Artículo 130 de la Ley 142 de 1994 modificado por el Artículo 18 de la Ley 689 de 2001.",
    invoice_content_second_text_legal_info: "De conformidad con el Decreto 2150 de 1995, la firma mecánica que aparece a continuación, tiene plena válidez para todos los efectos legales.",
    invoice_content_third_text_legal_info: "Para presentar reclamaciones referente a los cobros del presente documento ante nuestra oficina de PQR Art 115 ley 142 de 1994. Art 53 CREG 156 de 2011 lo puede hacer ingresando a la página web",
    invoice_content_fourth_text_legal_info: "y posteriormente hacer clic en PQR. En cumplimiento a la Ley 1266 del 2008 al no realizar pagos en las fechas establecidas se ve reflejado un reporte negativo en centrales de riesgo.",
    invoice_content_fifth_text_legal_info: "En caso de aplicar para el beneficio de exención de contribución porfavor ingresa a la página web",
    invoice_content_sixth_text_legal_info: "en la parte inferior haz clic en 'Beneficio contribución', y sigue las instrucciones.",
    invoice_content_seventh_text_legal_info: "Con la aceptación, expresa o tacita, del presente documento, autorizo a NEU Energy S.A.S. E.S.P. o cualquier tercero debidamente acreditado por este, a contactarme por cualquier medio en referencia al presente documento en caso de encontrarme en mora, con la finalidad mantener una comunicación efectiva y oportuna. Además, autorizó para recibir información por medio de correo electrónico, mensaje de texto, WhatsApp y correspondencia física.",
    invoice_content_eighth_text_legal_info: "Autorizo a NEU Energy S.A.S. E.S.P. (NIT 901302727-0) o a quién represente sus derechos u ostente en el futuro calidad de acreedor a consultar, reportar, conservar, suministrar, solicitar o divulgar a DATACREDITO central de información y de riesgo, toda la información referente a mi comportamiento comercial. Lo anterior implica que el cumplimiento o incumplimiento de mis obligaciones se reflejará en las mencionadas bases de datos, en donde se consigan de manera completa todos los datos referentes a mi actual y pasado comportamiento en general frente al cumplimiento de mis obligaciones.",
    invoice_content_text_pay_methods: "Métodos de pago",
    invoice_content_text_pay_web: "Paga por nuestra WEB",
    invoice_content_text_pay_app: "Paga por nuestra APP",
    invoice_content_text_pay_wire_transfer: "Transferencia Bancaria",
    invoice_content_first_text_pay_wire_transfer_detail: "Puedes realizar el pago a la cuenta corriente de Bancolombia",
    invoice_content_second_text_pay_wire_transfer_detail: "a nombre de Neu Energy S.A.S E.S.P y enviar el comprobante al correo",
    invoice_content_text_legal_representative: "Representante legal",
    invoice_content_text_taxes: "Aplica impuesto sobre las ventas",
    invoice_content_text_address: "Dirección",
    invoice_content_text_payment_way: "Forma de pago",
    invoice_content_text_payment_cash: "Contado",
    invoice_content_text_payment_method: "Medio de pago",
    invoice_content_text_debit_card: "Tarjeta Débito",
    invoice_content_text_support: "Atención al cliente",
    invoice_content_text_support_wsp: "Whatsapp",
    invoice_content_text_support_emergency: "Emergencias",
    invoice_content_text_footer_page: "Página",
    invoice_content_text_strong_neugets: "¡Recuerda!",
    invoice_content_first_text_neugets: "Tienes",
    invoice_content_second_text_neugets: "que puedes usar para pagar esta factura.",
    invoice_content_text_bill_detail: "Detalles de tu factura",
    invoice_content_text_bill_detail_info: "Conoce más detalles aquí",
    invoice_content_text_header_table_item: "ítem",
    invoice_content_text_header_table_detail: "Detalle",
    invoice_content_text_header_table_unit_value: "Valor unit.",
    invoice_content_text_header_table_concept_value: "Por concepto de",
    invoice_content_text_header_table_month_value: "Mes de participación",
    invoice_content_text_header_table_quantity: "Cant.",
    invoice_content_text_header_table_days_quantity: "Número de días",
    invoice_content_text_header_table_value: "Valor",
    invoice_content_text_subtotal: "Subtotal",
    invoice_content_text_iva: "IVA(19%)*",
    invoice_content_text_capital_record: "Abonos a capital",
    invoices_tooltip_previous_bill: "Anterior Factura",
    invoices_tooltip_next_bill: "Siguiente Factura",
    invoices_tooltip_download_bill: "Descargar",
    invoices_tooltip_search_bill: "Buscar",
    invoices_tooltip_close_bill: "Cerrar",
    invoices_tooltip_zoomin_bill: "Ampliar",
    always_on_indicator_title_percentage: "Porcentaje de consumo fantasma",
    always_on_indicator_text_percentage: " vs. mes anterior",
    always_on_indicator_title_tons_co2: "Toneladas de CO2",
    always_on_indicator_title_max_day: "Día de mayor consumo",
    always_on_indicator_title_cost: "Equivale a",
    power_factor_title: "Factor de Potencia",
    power_factor_label_phase: "Línea",
    power_factor_label_optime_range: "Factor de potencia en rango óptimo",
    power_factor_label_reactive_range: "Factor de potencia en rango reactivo",
    power_factor_label_deficient_range: "Factor de potencia en rango deficiente",
    tooltip_premium_monthly_consumption: "",
    tooltip_tariffs_records: "Comparación de la tarifa de NEU vs Comercializador incumbente",
    tooltip_hourly_active_energy: "Promedio de consumo de cada una de las 24 horas del día en el rango de fechas seleccionado",
    tooltip_hourly_distribution_active_energy: "",
    tooltip_total_payment_history: "Cobro total de la factura de energía por mes en cada sede",
    tooltip_daily_active_energy: "",
    tooltip_weekly_mean_active_energy: "Promedio de consumo de los días de la semana",
    tooltip_reactive_cash_flow: "Cobro de penalización de energía reactiva en exceso por mes",
    tooltip_weekly_active_energy: "",
    tooltip_monthly_active_energy: "Información de consumo y pago de energía activa por mes en cada sede.",
    tooltip_money_savings_history: "",
    tooltip_penalized_days_history: "Cantidad de horas por día en las que se presentó energía reactiva en exceso",
    tooltip_premium_daily_consumption: "",
    tooltip_reactive_excess_history: "Registro de las horas donde se está presentando exceso de energía reactiva inductiva y/o capacitiva",
    tooltip_money_savings_table: "",
    tooltip_premium_weekly_consumption: "",
    tooltip_premium_measurement: "",
    genius_multipleselect_label_new_graphs: "Nuevo",
    invoice_content_text_negative_balance: "Saldos a favor",
    invoice_content_text_new_negative_balance: "Nuevo saldo a favor",
    tooltip_a_p: "Este es un cobro municipal y este mismo define el valor a cobrar",
    tooltip_acuerdo: "",
    tooltip_aj: "",
    tooltip_aj_ap: "",
    tooltip_aj_con_cv: "",
    tooltip_aj_con_d: "",
    tooltip_aj_con_g: "",
    tooltip_aj_con_pr: "",
    tooltip_aj_con_r: "",
    tooltip_aj_con_t: "",
    tooltip_aj_cons_cont: "",
    tooltip_aj_consumo_kwh: "",
    tooltip_aj_cont: "",
    tooltip_aj_cu: "",
    tooltip_aj_cu_cont: "",
    tooltip_aj_ea: "",
    tooltip_aj_ec: "",
    tooltip_aj_ee2: "",
    tooltip_aj_er: "",
    tooltip_aj_intmora: "",
    tooltip_aj_intzero: "",
    tooltip_aj_prop_terc: "",
    tooltip_aj_re: "",
    tooltip_aj_sdl: "",
    tooltip_aj_seg: "",
    tooltip_aj_subs: "",
    tooltip_cont: "Equivale al 20% del valor total por energía activa y reactiva, y este es un gravamen tributario",
    tooltip_ea: "",
    tooltip_ea_cv: "",
    tooltip_ea_d: "",
    tooltip_ea_g: "",
    tooltip_ea_pr: "",
    tooltip_ea_r: "",
    tooltip_ea_t: "",
    tooltip_ec: "",
    tooltip_ee: "",
    tooltip_ee2: "",
    tooltip_eem: "",
    tooltip_er: "Valor a cobrar por el exceso de energía reactiva inductiva y capacitiva",
    tooltip_ing_ter: "",
    tooltip_int_med: "",
    tooltip_int_mora: "",
    tooltip_int_mora_zero: "",
    tooltip_int_neupay: "",
    tooltip_l1955: "",
    tooltip_mat_saas: "",
    tooltip_med: "",
    tooltip_mo_saas: "",
    tooltip_neu_comision: "",
    tooltip_neu_datos: "",
    tooltip_neu_inv114: "",
    tooltip_neu_inv126: "",
    tooltip_neu_inv131: "",
    tooltip_neu_inv132: "",
    tooltip_neu_inv136: "",
    tooltip_neu_ops1: "",
    tooltip_neu_ops2: "",
    tooltip_neu_ops3: "",
    tooltip_neu_ops4: "",
    tooltip_neu_ops5: "",
    tooltip_neu_pay: "",
    tooltip_neu_plus: "",
    tooltip_neu_serv: "",
    tooltip_neu_serv1: "",
    tooltip_neu_serv2: "",
    tooltip_neu_serv3: "",
    tooltip_neu_serv4: "",
    tooltip_neu_serv5: "",
    tooltip_neu_serv6: "",
    tooltip_neu_serv7: "",
    tooltip_neu_serv8: "",
    tooltip_neu_telem: "",
    //tooltip_neu+:"",
    tooltip_reint_cyg: "",
    tooltip_saldo: "",
    tooltip_seg_conv: "Este es un cobro departamental y estos mismos definen los parámetros para su cobro. Los únicos departamentos  con este cobro son : Sucre, Valle del Cauca y Átlantico.",
    tooltip_sist_solar: "",
    tooltip_subs: "",
    tooltip_reactive_inductive: "Energía generada por equipos industriales como motores, compresores,bombas.",
    tooltip_reactive_capacitive: "Energía generada por componentes electrónicos,luminarias.",
    tooltip_graph_energy_no_match: "no puede compararse con",
    tooltip_graph_energy_no_match_reason: "por diferencias de unidades de medida",
    user_title_section_alerts: "Mis Alertas",
    energy_graph_no_data: "Sin datos",
    genius_download_file_report: "Descargar Reporte",
    genius_download_html_report: "Descargar HTML",
    genius_download_excel_report: "Descargar Excel",
    // Onboarding
    onboarding_drawer_title: "Tutoriales",
    onboarding_drawer_faqs: "Preguntas frecuentes",
    onboarding_drawer_show: "Mostrar todos",
    onboarding_drawer_hide: "Ocultar",
    onboarding_payment_title: "¿Cómo pago mi factura?",
    onboarding_payment_description: "Realiza el pago de tu factura desde la plataforma.",
    onboarding_invoice_title: "¿Cómo busco y visualizo una factura?",
    onboarding_invoice_description: "Filtra tus facturas.",
    onboarding_dashboard_title: "¿Cómo busco otro servicio vinculado a mi cuenta?",
    onboarding_dashboard_description: "Visualiza otro de tus servicios.",
    onboarding_new_user_title: "¿Cómo agrego un usuario?",
    onboarding_new_user_description: "Envía una invitación a miembro de tu empresa.",
    onboarding_new_alert_title: "¿Cómo creo una alerta?",
    onboarding_new_alert_description: "Las alertas permiten hacer seguimiento a tus servicios.",
    onboarding_service_detail_title: "¿Cómo entro al detalle de mis servicios?",
    onboarding_service_detail_description: "Busca el detalle de tu servicio.",
    onboarding_service_picker_title: "¿Cómo puedo seleccionar un servicio específico?",
    onboarding_service_picker_description: "Busca el servicio que necesitas.",
    onboarding_graph_type_picker_title: "¿Cómo selecciono una variable en la gráfica?",
    onboarding_graph_type_picker_description: "Varia la visualización de la gráfica.",
    onboarding_graph_period_picker_title: "¿Cómo modifico el rango de días en la gráfica?",
    onboarding_graph_period_picker_description: "Hora, día, semana, mes o año.",
    onboarding_graph_export_data_title: "¿Cómo puedo descargar los datos de mi servicio?",
    onboarding_graph_export_data_description: "Un CSV con tus datos.",
    onboarding_graph_calendar_picker_title: "¿Puedo hacer una selección personalizada de fechas?",
    onboarding_graph_calendar_picker_description: "Selecciona un rango específico.",
    onboarding_service_title_step1: "Multiple selección según tus necesidades:",
    onboarding_service_title_step2: "Descubre el detalle desde un solo lugar:",
    onboarding_service_description_step1: "Puedes seleccionar uno o máximo 3 servicios vinculados a tu cuenta en este menú.",
    onboarding_service_description_step2: "También puedes ver los datos más importantes de este servicio al desplegar el detalle.",
    onboarding_service_picker_title_step1: 'Desde aquí haz la selección de tus servicios:',
    onboarding_service_picker_title_step2: 'No te afanes si no encuentras lo que buscas:',
    onboarding_service_picker_title_step3: 'Selecciona los servicios que quieres comparar:',
    onboarding_service_picker_description_step1: 'Puedes seleccionar uno o máximo 3 servicios vinculados a tu cuenta. Para descubrir cómo da click al botón de (+) y navega por tus servicios',
    onboarding_service_picker_description_step2: 'Filtra por alguno de nuestros servicios o busca una ubicación en especifico desde este botón',
    onboarding_service_picker_description_step3: 'Relaciona los servicios que vas a comparar con los tipos de gráfica (Ej: Comparar consumo de 2 servicios).',
    onboarding_graph_calendar_title_step1: 'Personaliza tus rangos de fecha:',
    onboarding_graph_calendar_title_step2: 'Te ofrecemos filtros establecidos:',
    onboarding_graph_calendar_description_step1: 'En el icono de calendario puedes seleccionar un rango especifico o hacer click en alguna de las opciones predeterminadas.',
    onboarding_graph_calendar_description_step2: "Puedes hacer click en alguno de los accesos rápidos, que te muestran la ultima semana, las ultimas 2 semanas y el ultimo mes.",
    onboarding_graph_export_data_title_step1: "Completa la información para descargar los datos de tu servicio:",
    onboarding_graph_export_data_title_step2: "Exporta la gráfica y los datos de tu servicio:",
    onboarding_graph_export_data_description_step1: "Haz clic en el botón de descarga ubicado junto a la selección de rango de fechas para obtener un archivo con los datos.",
    onboarding_graph_export_data_description_step2: "Podrás descargar los datoss en un archivo CSV, según la selección de gráfica y rango de fecha.",
    onboarding_graph_period_title_step1: "Cambia la visualización a tu preferencia:",
    onboarding_graph_period_title_step2: "Rangos establecidos para navegar:",
    onboarding_graph_period_title_step3: "Rangos establecidos para navegar:",
    onboarding_graph_period_title_step4: "Rangos establecidos para navegar:",
    onboarding_graph_period_title_step5: "Rangos establecidos para navegar:",
    onboarding_graph_period_description_step1: "Desde aquí puedes escoger el rango de fecha que deseas visualizar en la gráfica.",
    onboarding_graph_period_description_step2: "Si seleccionas Hora a hora vas a poder visualizar todo 1 día por 24 horas.",
    onboarding_graph_period_description_step3: "Cuando seleccionas Rango semanal vas visualizar la semana por días (7 días).",
    onboarding_graph_period_description_step4: "La selección de Rango Mensual te permite ver el mes completo por días (30 días) y hacer selecciones personalizas.",
    onboarding_graph_period_description_step5: "La selección del Rango Anual te permite ver todo un año por meses (12 meses).",
    onboarding_graph_type_title_step1: "La selección de servicios se ve reflejada acá:",
    onboarding_graph_type_title_step2: "Selecciona el tipo de gráfica que desees ver:",
    onboarding_graph_type_title_step3: "Verifica la unidad de medida:",
    onboarding_graph_type_title_step4: "Algunas variables están agrupadas:",
    onboarding_graph_type_description_step1: "Los servicios que se ven aquí son los mismos seleccionaste en el menú de servicios",
    onboarding_graph_type_description_step2: "Tienes acceso a una gran variedad de tipos de gráfica (Consumo, Corriente, Voltaje, etc.) desde este botón.",
    onboarding_graph_type_description_step3: "Algunas variables no se pueden comparar entre sí por las diferencias en unidad de medida:Consumo (kWh) vs. Corriente (Amp)",
    onboarding_graph_type_description_step4: "Revisa el icono que esta al lado de la variable para distinguirlas. Por ejemplo si seleccionas Energía Reactiva, puedes hacer la distinción entre Capacitiva, En Exceso o Inductiva.",
    onboarding_invoice_title_step1: "Encuentra las facturas que necesitas:",
    onboarding_invoice_title_step2: "No te pierdas ningún detalle de tu factura:",
    onboarding_invoice_description_step1: "Busca por el nombre del servicio o filtrar las facturas según el mes a pagar, o el historial de las que ya fueron pagadas.",
    onboarding_invoice_description_step2: "Visualizar la factura y todo sus componentes en la totalidad de tu pantalla.",
    onboarding_payment_title_step1: "Realiza el pago desde la plataforma:",
    onboarding_payment_title_step2: "Selecciona tu forma de pago pago:",
    onboarding_payment_title_step3: "Cuando pagas con tarjeta de credito:",
    onboarding_payment_title_step4: "Te ofrecemos otra forma de pago:",
    onboarding_payment_description_step1: "Selecciona una o más facturas, dale click al botón pagar y sigue los pasos para completar el proceso desde nuestra pasarela de pago.",
    onboarding_payment_description_step2: "Puedes completar tu pago con tarjeta de crédito o PSE, y todo desde la plataforma.",
    onboarding_payment_description_step3: "Solo es necesario completar la información de pago y seleccionar la cantidad de cuotas.",
    onboarding_payment_description_step4: "Incluso puedes usar los NEUgets vinculados a tu cuenta para pagar.",
    onboarding_user_title_step1: "Puedes invitar a nuevos colaboradores:",
    onboarding_user_title_step2: "A tener en cuenta:",
    onboarding_user_title_step3: "Haz los cambios que necesites:",
    onboarding_user_description_step1: "Invita a otros usuarios a tus proyectos y dales acceso a todo lo que necesiten de tu cuenta.",
    onboarding_user_description_step2: "Para agregar a un usuario, es importante completar este formulario.",
    onboarding_user_description_step3: "Puedes modificar el permiso que le diste a otros usuarios o moverlos a otro proyecto.",
    onboarding_profile_title_step1: "Desde tu perfil puedes:",
    onboarding_profile_title_step2: "Puedes crear alertas para tus proyectos:",
    onboarding_profile_title_step3: "Agrega  y editar los usuarios que necesites:",
    onboarding_profile_title_step4: "Accede a los demás tutoriales de la plataforma:",
    onboarding_profile_description_step1: "Configurar tus datos personales y los usuarios vinculados a cada proyecto de tu cuenta.",
    onboarding_profile_description_step2: "Para hacerle seguimiento a tus proyectos, verificar la energía reactiva o el consumo.",
    onboarding_profile_description_step3: "También tienes la sección para agregar usuarios a cada uno de tus proyectos, o editar el acceso a usuarios que ya agregaste.",
    onboarding_profile_description_step4: "En esta sección tendrás acceso a los tutoriales  disponibles dentro de la plataforma.",
    onboarding_dashboard_title_step1: "¡Hola!",
    onboarding_dashboard_title_step2: "Tu cuenta, un mundo de posibilidades:",
    onboarding_dashboard_title_step3: "Aprende a navegar por cada servicio:",
    onboarding_dashboard_title_step4: "Accede a los servicios de emergencia:",
    onboarding_dashboard_title_step5: "Envía tus requerimientos desde la plataforma:",
    onboarding_dashboard_title_step6: "Mantente al tanto de todo lo que sucede:",
    onboarding_dashboard_title_step7: "Accede a más NEUGets (Green energy tokens):",
    onboarding_dashboard_description_step1: "Conoce como navegar y sacarle el mejor provecho a la plataforma de ERCO/NEU.",
    onboarding_dashboard_description_step2: "Accede a los servicios dentro del ecosistema de NEU/ERCO desde esta sección.",
    onboarding_dashboard_description_step3: "Navega por cada sección dandole click al icono del servicio: Energy, NEU+, Genius y Efficiency.",
    onboarding_dashboard_description_step4: "En caso que tengas una falla en tu suministro de energía, con respuesta a emergencias 24/7.",
    onboarding_dashboard_description_step5: "En el centro de ayuda puedes mandar una solicitud cuando tengas una queja o requerimiento",
    onboarding_dashboard_description_step6: "Accede a las notificaciones de todos tus proyectos y a las alertas creadas para cada uno.",
    onboarding_dashboard_description_step7: "Con tus NEUgets puedes pagar la factura de energía y ganar más al referir nuevas personas.",
    onboarding_alert_title_step1: "Programa tus alertas para estar al tanto de todo:",
    onboarding_alert_title_step2: "Todo esto puedes hacer con una alerta:",
    onboarding_alert_title_step3: "A tener en cuenta:",
    onboarding_alert_title_step4: "Selecciona la variable de energía:",
    onboarding_alert_title_step5: "Determina la regla que mide tu alerta:",
    onboarding_alert_title_step6: "Establece el valor numérico para tu alerta:",
    onboarding_alert_title_step7: "Establece a donde debe llegar la notificación:",
    onboarding_alert_title_step8: "Te notificaremos cuando se cumpla tu alerta:",
    onboarding_alert_description_step1: "Las alertas son la manera más sencilla y personalizada de hacerle seguimiento a tus servicios.",
    onboarding_alert_description_step2: "Seguimiento a tus servicios, verifica el consumo o la energía reactiva, establece un límite y mucho más. Da click al botón de agregar alerta para descubrir cómo.",
    onboarding_alert_description_step3: "Para crear una alerta es necesario definir que servicio y que tipo de variable de energía vas a seleccionar.",
    onboarding_alert_description_step4: "Puedes seleccionar entre varios tipos de variables como: consumo, energía reactiva, corriente, potencia, voltaje, entre otros.",
    onboarding_alert_description_step5: "Puedes seleccionar entre 3 tipos de reglas para establecer tu alerta.",
    onboarding_alert_description_step6: "Para esta sección puedes poner un valor numérico para tu alerta, puede ser un total o un promedio de datos.",
    onboarding_alert_description_step7: "Puedes seleccionar el canal por donde enviar la notificación de la alerta.",
    onboarding_alert_description_step8: "Al establecer una alerta te notificaremos cuando alguno de tus proyectos sobrepase la condición que programaste.",
    energy_graph_group_average: "Promedio (en gráfica)",
    onboarding_walktrough_title: "Accede a los demás tutoriales de la plataforma:",
    onboarding_walktrough_description: "Ingresa a tu perfil, encontrarás la sección de todos los tutoriales para continuar este o iniciar uno nuevo.",
    last_monthly_generation: "Generación del último mes",
    invoices_text_empty_bills_pending: "Buen trabajo! No tienes facturas pendientes.",
    invoices_text_empty_bills_paid: "No tienes facturas pagadas, si tienes facturas pendientes recuerda ponerte al dia.",
    invoices_text_empty_bills_due: "Buen trabajo! No tienes facturas vencidas.",
    generation_per_equipment: "Generación por equipo",
    generation_vs_irradiance: "Generación vs Irradiancia",
    generation: "Generación",
    temperature_plot: "Temperatura",
    generation_variation: "Variación de generación",
    invoices_text_empty_bills_overdue: "Buen trabajo! No tienes facturas vencidas.",
    invoices_state_clear_filters: "Limpiar filtro",
    tooltip_last_monthly_generation: "Información de la generación de energía de los últimos meses",
    tooltip_generation_per_equipment: "Información de la generación de energía desagregada por inversor",
    tooltip_generation_vs_irradiance: "Comparativo entre la generación de energía del servicio y la irradiancia solar",
    tooltip_generation: "Distribución diaria de la generación de energía",
    tooltip_temperature_plot: "Comparativo entre la temperatura ambiental y la temperatura de los paneles solares",
    user_selected_label_job_area: "Áreas seleccionadas",
    tooltip_generation_variation: "Variación de la generación del mes comparado con el mes anterio",
    savings: "Descripción de los ahorros del proyecto",
    alerts_title_interval_time: 'Intervalo horario',
    alerts_interval_time_body_frequency: 'Configura el horario de medición de tu alerta',
    alerts_interval_time_title_days: 'Selecciona los días',
    alerts_interval_time_title_range: 'Rango horario',
    alerts_interval_time_title_body: 'Configura unas horas específicas para el envío de tu alerta',
    alerts_title_initial_time: 'Inicio:',
    alerts_title_final_time: 'Final:',
    alert_time_am: 'AM',
    alert_time_pm: 'PM',
    alerts_new_alert_service_item: 'Servicio',
    alerts_new_alert_service_label: 'Selecciona el servicio',
    alerts_new_alert_service_example: 'Puedes seleccionar uno o más servicios',
    alerts_new_alert_variable_item: 'Variable',
    alerts_new_alert_variable_label: 'Selecciona la variable',
    alerts_new_alert_variable_example: 'La variable eléctrica de la alerta',
    alerts_new_alert_rule_item: 'Regla',
    alerts_new_alert_rule_label: 'Selecciona la regla',
    alerts_new_alert_rule_example: 'La variable que mide la alerta',
    alerts_new_alert_value_item: 'Valor',
    alerts_new_alert_value_label: 'Selecciona el valor',
    alerts_new_alert_value_example: 'El número o promedio que mide la alerta',
    new_alert_type_title: 'Tipo de alerta',
    new_alert_select_type: 'Selecciona el tipo de alerta',
    alerts_new_alert_channel_example: 'El medio por el que llega la alerta',
    new_alert_schedule_description: 'Agregar un horario de envío de alerta',
    popover_duplicate_alert: 'Duplicar alerta',
    popover_delete_alert: 'Eliminar alerta',
    accounts_id_account: 'ID Cuenta',
    accounts_location: 'Ubicación',
    accounts: 'Cuentas',
    graph_operation_state_no_record: 'Sin registro',
    graph_operation_state_process: 'En proceso',
    graph_operation_state_standby: 'Standby',
    graph_operation_state_checking: 'Chequeando',
    graph_operation_state_fail: 'Falla',
    energy_no_users: 'No hay usuarios para mostrar',
    energy_done_button: "Hecho",
    energy_click_to_change: "Toca aquí para cambiar",
    reports_select_all: 'Seleccionar todos',
    invoces_more_invoces: 'Más facturas del periodo',
    alerts_label_title_empty_state_registers: 'No hay alertas cumplidas en este momento',
    alerts_label_body_empty_state_registers: 'Agrega una nueva alerta desde tu perfil o haciendo click en el botón.',
    nps_beta_survey_usability: 'Facilidad de uso',
    nps_beta_survey_information: 'Información',
    nps_beta_survey_design: 'Diseño',
    nps_beta_survey_other: 'Otro...',
    nps_beta_survey_alert_error: '¡Oops! Parece que olvidaste completar algunos campos.',
    nps_beta_survey_your_opinion_counts: '¡Tu opinión cuenta!',
    nps_beta_survey_improve_experience: 'Ayúdanos a mejorar tu experiencia',
    nps_beta_survey_rate_beta_experience: '¿Cómo calificarías tu experiencia en esta plataforma beta?',
    nps_beta_survey_dislike: 'No me gustó',
    nps_beta_survey_can_improve: 'Puede mejorar',
    nps_beta_survey_great: 'Excelente',
    nps_beta_survey_send_rating: 'Enviar calificación',
    nps_beta_survey_thanks: '¡Gracias por calificarnos!',
    nps_beta_survey_highlight : '¿Qué sería lo que más resaltarías de esta nueva plataforma?',
    nps_beta_survey_send: 'Enviar  y finalizar',
    nps_beta_survey_rate_experience: 'Califica tu experiencia',
    onboarding_generation_title1: '¡Nos renovamos para tí! Conoce ENERG:',
    onboarding_generation_text1: 'ENERG tiene una experiencia más intuitiva, con un diseño renovado y mejoras basadas en sugerencias de clientes como tú.',
    onboarding_generation_title2: 'Mide y visualiza tu generación en tiempo real',
    onboarding_generation_text2: 'Podrás ver las gráficas de cómo está generando energía tu sistema en diferentes escalas de tiempo (horaria, semanal y mensual), gráficas de corriente, tensión, potencia activa, factor de potencia, entre otros.',
    onboarding_generation_title3: 'Descubre tu eficiencia energética con generación',
    onboarding_generation_text3: 'Calculamos tu ahorro teniendo como base la tarifa de tu comercializador de energía. Además,podrás hacer un seguimiento del impacto económico y ambiental de tu sistema.',
    onboarding_generation_title4: 'Una sola plataforma para todas las soluciones',
    onboarding_generation_text4: '¿Quieres aumentar tu eficiencia energética? Aquí podrás encontrar las soluciones que te van a permitir ser más sostenible. Descubre todo lo que ENERG tiene para ti.',
    onboarding_preonboarding_title1: '¡Un par de minutos más para poder acceder a los beneficios!',
    onboarding_preonboarding_text1: 'Ten en cuenta las etapas del proceso de operaciones por las que va a pasar tu proyecto:',
    onboarding_preonboarding_title2: 'Realiza el seguimiento de tu proceso de operaciones',
    onboarding_preonboarding_text2: 'Dentro de la plataforma puedes verificar el estado actual de tu proceso, validar datos como el consumo y el nivel de voltaje de tu proyecto',
    onboarding_preonboarding_title3: 'Responde cualquier duda importante desde el chat',
    onboarding_preonboarding_text3: 'Tienes acceso a un chat individual con tu coordinador de operaciones para hacer seguimiento personalizado a tu proceso de instalación',
    onboarding_consumption_title1: '¡Ten a la mano el control de la energía de tu empresa!',
    onboarding_consumption_text1: 'Haz más, gasta menos y cuida el planeta, con el ecosistema de energía mejor optimizado para tus necesidades.',
    onboarding_consumption_title2: 'Accede a toda la información de tu consumo',
    onboarding_consumption_text2: 'Consume energía de una manera eficiente y haz seguimiento con reportes personalizados de energía, detallados y creados con tu data.',
    onboarding_consumption_title3: 'Entiende lo que pagas en tu factura de energía',
    onboarding_consumption_text3: 'Con nuestra factura interactiva podrás realizar un solo pago, tener a la mano el histórico de todo tu consumo y analizar tu gasto.',
    onboarding_consumption_title4: 'Mide y visualiza tu consumo en tiempo real',
    onboarding_consumption_text4: 'Crea y modifica alarmas personalizadas, mide diferentes variables de la energía y mantente al tanto de los cambios atípicos de tu consumo.',
    onboarding_modal_description: 'Te seleccionamos a tí para ser uno de los primeros en probarla, podrás dejar tus comentarios en la sección que encontrarás en la esquina inferior derecha.',
    onboarding_modal_finally: 'Finalizar',
    onboarding_modal_continue: 'Continuar',
    dashboard_steps_power_outages: 'Cortes inesperados de luz',
    dashboard_steps_short_circuit: 'Cortocircuito en la ubicación',
    dashboard_steps_support: 'Soporte',
    dashboard_steps_billing_problems: 'Problemas de facturación',
    dashboard_steps_payment_methods: 'Medios de pago',
    quality_graph_lines: 'Líneas',
    quality_graph_power: 'Potencia',
    quality_graph_electricity: 'Corriente',
    quality_graph_variables: 'Variables',
    chatbot_aids1: '¿Cuánto he consumido este año?',
    chatbot_aids2: '¿Cuál fue mi energía reactiva en junio de 2023?',
    chatbot_aids3: '¿Qué día se alcanzó el pico máximo de consumo de energía en el último año?',
    roles_commercial: 'Comercial',
    roles_operations: 'Operaciones',
    roles_invoicing: 'Facturación',
    roles_environmental: 'Ambiental',
    roles_financial: 'Financiero',
    roles_marketing: 'Marketing',
    roles_legal: 'Legal',
    roles_not_apply: 'N/A',
    roles_innovation: 'Innovacion',
    roles_manager: 'Gerente',
    invoices_paid_text_commission: 'Comisión',
    tutorial_start_button: 'Empezar',
    tutorial_skip_button: 'Omitir',
    tutorial_got_it: 'Entendido',
    report_type: 'Tipo de reporte',
    consumption_report: 'Reporte de Consumo',
    generation_report:'Reporte de Generación',
    genius_report_monthly_consumption: 'Consumo mensual y Facturación',
    genius_report_submeasurements: 'Submediciones',
    genius_report_detailed_consumption: 'Consumo detallado',
    genius_report_reactive_energy: 'Energía reactiva',
    genius_report_savings: 'Ahorros',
    genius_report_generation: 'Generación',
    picker_yes_button: 'Si',
    picker_no_button: 'No',
    lumi_title: 'Pronto conocerás el poder de Lumi',
    lumi_first_text: 'Prepárate para conocer una nueva era de innovación y asistencia personalizada.',
    lumi_second_text: '¡No te pierdas esta emocionante novedad que cambiará la forma en la que interactúas con nuestra plataforma!',
    lumi_title_card: '¡Nos encanta contar contigo!',
    lumi_first_text_card: 'Tan pronto como esté disponible, serás el primero en enterarte. Te mantendremos al tanto de todas las novedades sobre Lumi.',
    lumi_second_text_title: '¡Prepárate para vivir una experiencia única!',
    lumi_do_click_here: 'Haz click aquí para ser el primero en conocer la experiencia',
    lumi_hey: 'Hola',
    lumi_question: '¿Cómo te puedo ayudar hoy?',
    lumi_you_chat: 'Tú',
    lumi_explanation: 'Te lo explico todo paso a paso:',
    lumi_go_tutorial: 'Ir a tutorial',
    lumi_continue_conversation: 'Continuar esta conversación',
    lumi_ask_me: 'Pregúntame lo que quieras...',
    lumi_consulting: 'Consultando',
    lumi_projects: 'proyectos',
    service_type_energy: 'Energía',
    service_type_plus: 'Plus',
    service_type_zero: 'Financieros',
    service_type_generation: 'Generación',
    graphs_go_back_button: 'Regresar',
    export_graph_example_filename: 'Ej: informe_28_julio',
    pagination: 'Pág.',
    pagination_of: 'de',
    services_picker_select_all: "Seleccionar totos",
    services_picker_descelect_all: "Deseleccionar totos",
    energy_graph_plus_button: 'Ir a Plus'
};

export default lang;